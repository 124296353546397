// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
$border-color: #efefef;

$font-sizes: (
  1: 2rem,
  2: 1.5rem,
  3: 1.125rem,
  4: 1rem,
  5: 0.875rem,
  6: 0.75rem,
);

// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 4. Include any optional Bootstrap CSS as needed
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/modal';

$utilities: map-merge(
  $utilities,
  (
    'font-size':
      map-merge(
        map-get($utilities, 'font-size'),
        (
          values: $font-sizes,
        )
      ),
  )
);

$spacers: (
  10: $spacer * 10,
);

$utilities: map-merge(
  $utilities,
  (
    'padding-top':
      map-merge(
        map-get($utilities, 'padding-top'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'padding-top'), 'values'),
              ($spacers)
            ),
        )
      ),
  )
);

$all-colors: map-merge-multiple(
  $blues,
  $indigos,
  $purples,
  $pinks,
  $reds,
  $oranges,
  $yellows,
  $greens,
  $teals,
  $cyans
);

$utilities: map-merge(
  $utilities,
  (
    'color':
      map-merge(
        map-get($utilities, 'color'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'color'), 'values'),
              ($all-colors)
            ),
        )
      ),
  )
);

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '../node_modules/bootstrap/scss/utilities/api';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

// 6. Add additional custom code here
:root {
  --primary: var(--primary);
  --secondary: var(--secondary);
  --green: #52c41a;
  --box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'greycliff-cf';
  background-color: #f9f9f9;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

// font
.fs-sm {
  font-size: 0.875rem;
}

// button
.btn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  align-items: center;
}

// border
.rounded-4 {
  border-radius: 10px;
}

// form
.form-select {
  height: 3rem;
}
